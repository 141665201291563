<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'12'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>案例列表</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>案例列表</span>
				</div>
				<div class="left-list-item" @click="leftClick('1')">
					<span>案例分类</span>
				</div>
			</div>
			<div class="right-next">
				<div class="date-table">
					<div class="title">
						<span>案例列表</span>
					</div>
					<div class="operation">
						<el-button type="primary" @click="add">添加</el-button>
					</div>
					<!-- <div class="date-search">
						<div class="left">
							<span>请选择时间：</span>
						</div>
						<div class="right">
							<el-date-picker class="ml-50" v-model="starttime" type="date" placeholder="请选择起始日期"
								:picker-options="pickerOptions" value-format="yyyy-MM-dd">
							</el-date-picker>
							<el-date-picker class="ml-50" v-model="endtime" type="date" placeholder="请选择结束日期"
								:picker-options="pickerOptions" value-format="yyyy-MM-dd">
							</el-date-picker>
							<el-select class="ml-50" v-model="shopiing" placeholder="商品品种" clearable>
								<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
							<div class="ml-50 mt-20">
								<el-select v-model="province" @change="provinceChange" placeholder="请选择省份" clearable>
									<el-option v-for="item in provinceList" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
								<el-select class="ml-50" v-model="city" placeholder="请选择市" clearable>
									<el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
								<el-button icon="el-icon-search" type="primary" @click="getList">搜索</el-button>
							</div>
						</div>
					</div> -->
					<div class="mt-20"></div>
					<el-table :data="tableData.data" border style="width: 100%">
						<el-table-column prop="id" label="ID" width="180" align="center">
						</el-table-column>
						<el-table-column prop="name" label="案例名称" width="180" align="center">
						</el-table-column>
						<el-table-column prop="type_id" label="案例分类" width="180" align="center">
						</el-table-column>
						<el-table-column prop="product_type" label="案例文件类型" width="180" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑
								</el-button> -->
								<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="bottom-paging">
						<!-- tableData.data.order_total -->
						<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="page" :page-sizes="[5, 10, 20, 30, 100]" :page-size="num"
							layout="total, prev, pager, next, sizes" :total="tableData.total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'],
				tableData: [],
				starttime: "",
				endtime: "",
				classList: [],
				cityList: [],
				provinceList: [],
				shopiing: "",
				city: "",
				province: "",
				isCollapse: false,
				pickerOptions: {
					//控制时间范围
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * 60 * 1000;
					},
				},
				page: 1,
				num: 10
			};
		},
		created() {
			this.getList();
			// this.getCitylist();
			// this.getClass();
		},
		methods: {
			add() {
				this.$router.push('/productedit');
			},
			handleEdit(index, row) {
				console.log(index, row);
				this.$router.push({
					query: {
						id: row.id
					},
					name: 'productedit'
				});
			},
			handleDelete(index, row) {
				console.log(index, row);
				this.$confirm('此操作将永久删除该案例, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('del/product', {
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功！',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify.error({
								title: '提示',
								message: res.data.message
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			getClass() {
				this.$get('get/category', {
					page: 1,
					num: 999
				}).then(res => {
					this.classList = res.data.data.data;
				})
			},
			getProvincelist() {
				this.$get('get/city', {
					province_id: this.province
				}).then(res => {
					this.cityList = res.data.data;
				});
			},
			provinceChange() {
				this.city = "";
				this.getProvincelist();
			},
			getCitylist() {
				this.$get('get/province', {}).then(res => {
					this.provinceList = res.data.data;
				});
			},
			getList() {
				this.$get('product/get_list', {
					page: this.page,
					num: this.num
				}).then(res => {
					console.log(res);
					for(let i = 0; i < res.data.data.data.length; i++){
						let fileName = res.data.data.data[i].url.lastIndexOf(".");//取到文件名开始到最后一个点的长度
						let fileNameLength = res.data.data.data[i].url.length;//取到文件名长度
						let fileFormat = res.data.data.data[i].url.substring(fileName + 1, fileNameLength);//截
						res.data.data.data[i].product_type = fileFormat;
					}
					this.tableData = res.data.data;
				})
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
        this.num = val;
				this.getList();
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
        this.page = val;
				this.getList();
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/product") {
					this.$router.push("/product");
				} else if (index == 1 && this.$route.path != "/producttype") {
					this.$router.push("/producttype");
				} else {
					this.reload();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.date-table {
			margin-bottom: 20px;

			.operation {
				margin-top: 30px;

				.el-button {
					width: 100px;
				}
			}

			.cityNumber {
				margin-top: 30px;
				padding-left: 140px;
			}

			.date-search {
				margin-top: 30px;

				.left,
				.right {
					display: inline-block;
				}

				.right {
					width: 900px;
					vertical-align: top;
				}

				.el-button {
					margin-left: 56px;
					padding: 12px 85px;
				}
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
